.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 8vh;
    position: fixed;
    background-color: rgba(255, 255, 255, 0.6);
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    box-sizing: border-box;
    z-index: 5;
    .header__logo {
        padding-left: 4.2vw;
        height: 100%;
        > img {
            height: 100%;
        }
    }
    .header__menu {
        align-items: center;
        height: 50%;
        padding-right: 4.2vw;
        cursor: pointer;
        > img {
            height: 100%;
            width: 90%;
        }
    }
    @include mobile {
        height: 50px;
    }
}
.social-icons {
    position: fixed;
    bottom: 10vh;
    right: 4.2vw;
    z-index: 2;
    > a {
        display: flex;
        align-items: center;
        justify-content: center;
        border: 0px solid transparent;
        background-color: white;
        border-radius: 50%;
        width: 33px;
        height: 33px;
        margin-top: 1vh;
        text-decoration: none;
        i {
            color: black;
        }
        &:hover {
            box-shadow: 0px 0px 4px 4px rgba(0, 0, 0, 0.25);
            outline: 4px solid rgba(255, 255, 255, 0.36);
            i {
                color: #2699fb;
            }
            transition: 0.2s;
            @include mobile {
                outline: none;
                border: 4px solid rgba(255, 255, 255, 0.36);
                box-sizing: border-box;
            }
        }
    }
}