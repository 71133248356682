$desktop-width: 1024px;
$mobile-width: 480px;

@mixin mobile {
	@media (max-width: #{$mobile-width}) {
		@content;
	}
}
@mixin not-mobile {
	@media (min-width: #{$mobile-width}) {
		@content;
	}
}

@font-face {
	font-family: 'Montserrat-Bold';
	src: url('../public/assets/fonts/Montserrat-Bold.ttf') format('opentype');
}
@font-face {
	font-family: 'Montserrat-Medium';
	src: url('../public/assets/fonts/Montserrat-Medium.ttf') format('opentype');
}
@font-face {
	font-family: 'Montserrat-Regular';
	src: url('../public/assets/fonts/Montserrat-Regular.ttf') format('opentype');
}

.main {
	min-height: 100vh;
	overflow-x: hidden;
	font-family: 'Montserrat-Medium';
	@include mobile {
		width: 100%;
	}
	@import './styles/fixed-navbar.scss';

	@import './styles/hotspot-banner.scss';
	@import './styles/contact-form.scss';
	@import './styles/community.scss';
	@import './styles/event.scss';
	@import './styles/exclusive-content.scss';
	@import './styles/footer.scss';

}
