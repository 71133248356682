.contact-form-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(216, 237, 255, 0.5);
    height: 100vh;
    @include mobile {
        height: 535px;
    }
    > div {
        display: flex;
        justify-content: center;
        align-items: center;
        @include mobile {
            height: 535px;
            width: 100%;
        }
        .contact-form__column {
            width: 1028px;
            display: flex;
            justify-content: space-around;
            align-items: center;
            .contact-form__image {
                width: 341px;
                height: 476px;
                @include mobile {
                    display: none;
                }
            }
            .contact-form__form {
                margin-top: -4vh;
                color: #2699fb;
                width: 251px;
                height: 375px;
                @include mobile {
                    width: 80%;
                    height: 80%;
                }
                > h2 {
                    font-family: 'Montserrat-Bold';
                    font-size: 25px;
                    margin: 0;
                }
                > p {
                    margin: 0;
                    font-family: 'Montserrat-Regular';
                    font-size: 12px;
                }
                > form {
                    color: #2699fb;
                    margin-top: 20px;
                    width: 100%;
                    > input {
                        background-color: rgba(216, 237, 255, 0.5);
                        margin: 8px 0;
                        width: 100%;
                        height: 30px;
                        border: 1.5px solid #2699fb;
                        border-radius: 4px;
                        font-family: 'Montserrat-Regular';
                        font-size: 12px;
                        &:focus {
                            outline: 2px solid #2699fb;
                        }
                        &::placeholder {
                            color: #2699fb;
                        }
                    }
                    > select {
                        font-family: 'Montserrat-Regular';
                        font-size: 12px;
                        background-color: rgba(216, 237, 255, 0.5);
                        margin: 8px 0;
                        width: 258px;
                        height: 35px;
                        border: 1.5px solid #2699fb;
                        border-radius: 4px;
                        color: #2699fb;
                        &:focus {
                            outline: 2px solid #2699fb;
                        }
                        @include mobile {
                            width: 345px;
                            height: 35px;
                        }
                    }
                    .contact-form__form-checkbox {
                        color: #2699fb;
                        > label {
                            margin-top: 10px;
                            width: 251px;
                            height: 39px;
                            align-items: flex-start !important;

                            @include mobile {
                                width: 345px;
                                height: 39px;
                            }
                            > span {
                                font-family: 'Montserrat-Regular';
                                font-size: 10px !important;
                                .contact-form__form-checkbox-older,
                                .contact-form__form-checkbox-accept {
                                    cursor: pointer;
                                    &:hover {
                                        background-color: rgba(216, 237, 255, 1);
                                    }
                                }
                            }
                        }
                    }
                    .contact-form__form-submit {
                        display: flex;
                        justify-content: center;
                        text-align: center;
                        > button {
                            width: 100px;
                            height: 30px;
                            background-color: rgba(216, 237, 255, 0.5);
                            border: 1.5px solid #2699fb;
                            border-radius: 2px;
                            color: #2699fb;
                            font-family: 'Montserrat-Regular';
                            font-size: 10px;
                            display: inline-block;
                            cursor: pointer;
                            &:hover {
                                border: 1.5px solid #2699fb;
                                border-radius: 2px;
                                background-color: #2699fb;
                                color: white;
                            }
                        }
                    }
                }
            }
        }
    }
}