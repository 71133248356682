body {
  margin: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root{
  width: 100%;
}

::-webkit-scrollbar {
  width: 5px;
  border-radius: 1vw;
}
/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f16c;
  border-radius: 10px;
}
/* Handle */
::-webkit-scrollbar-thumb {
  background: #2699fb;
  border-radius: 10px;
}
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #2699fb;
}
