.community {
    height: 100vh;
    display: flex;
    flex-direction: column;
    background-color: #ffffff;
    align-items: center;
    @include mobile {
        width: 100%;
    }
    .community__title-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 20%;
        @include mobile {
            width: 90%;
            justify-content: flex-start;
            height: 5%;
        }
        .community__title {
            width: 1028px;
            color: #2699fb;
            margin-top: 7vh;
            @include mobile {
                width: 90%;
                margin-top: 10px;
            }
            > h1 {
                font-family: 'Montserrat-Bold';
                font-size: 45px;
                @include mobile {
                    font-size: 25px;
                }
            }
            > h3 {
                font-family: 'Montserrat-Bold';
                font-size: 25px;
                @include mobile {
                    display: none;
                }
            }
        }
    }
    .live-game__slider {
        display: flex;
        height: 20%;
        width: 1028px;
        @include mobile {
            width: 90%;
            height: 10%;
        }
        .swiper {
            width: 100%;
            height: 100%;
            .swiper-wrapper {
                display: flex;
                align-items: center;
                margin: 0 50px;
                @include mobile {
                    margin: 0 30px;
                }
                .live-game__slider__items {
                    .live-game__slider__item {
                        display: flex;
                        justify-content: center;
                        flex-direction: column;
                        align-items: center;
                        > a {
                            text-decoration: none;
                            .live-game__slider__image {
                                margin-top: 10%;
                                width: 96px !important;
                                height: 96px;
                                border-radius: 50%;
                                cursor: pointer;
                                @include mobile {
                                    width: 70px !important;
                                    height: 70px;
                                }
                                &:hover {
                                    outline: 3px solid #2699fb;
                                    @include mobile {
                                        outline: none;
                                        outline: 3px solid #2699fb;
                                        box-sizing: border-box;
                                    }
                                }
                            }
                        }
                        .live-game__slider__content {
                            display: flex;
                            justify-content: center;
                            > h3 {
                                display: flex;
                                justify-content: center;
                            }
                        }
                    }
                }
            }
        }
    }
    .community__title__2-wrapper {
        display: flex;
        width: 1028px;
        justify-content: space-between;
        color: #2699fb;
        @include mobile {
            display: none;
        }
        > h3 {
            font-family: 'Montserrat-Bold';
            font-size: 25px;
        }
        .community__title__2 {
            display: flex;
            align-items: center;
            .community__title__2__social-icons {
                > i {
                    font-size: 40px;
                    margin: 0 10px;
                    color: #2699fb;
                    cursor: pointer;
                }
            }
            .community__title__2__search-container {
                display: flex;
                align-items: center;
                margin-left: 10px;
                > form {
                    > input {
                        margin: 8px 0;
                        width: 233px;
                        height: 40px;
                        border: 1.5px solid #2699fb;
                        border-radius: 4px;
                        font-family: 'Montserrat-Regular';
                        font-size: 12px;
                        &:focus {
                            outline: 2px solid #2699fb;
                        }
                        &::placeholder {
                            color: #2699fb;
                        }
                    }
                    > span {
                        color: #2699fb;
                        > i {
                            position: absolute;
                            margin: 22px 0 0 -25px;
                        }
                    }
                }
            }
        }
    }
    .community__title__2-mobile-wrapper {
        display: flex;
        width: 90%;
        flex-direction: column;
        color: #2699fb;
        @include not-mobile {
            display: none;
        }
        .community__title__2-mobile {
            display: flex;
            align-items: center;
            justify-content: space-between;

            > h3 {
                font-family: 'Montserrat-Bold';
                font-size: 20px;
            }
            .community__title__2-mobile__social-icons {
                > i {
                    font-size: 40px;
                    margin: 0 10px;
                    color: #2699fb;
                    cursor: pointer;
                }
            }
        }
        .community__title__2-mobile__search-container {
            display: flex;
            align-items: center;
            margin-left: 10px;
            > form {
                > input {
                    margin: 8px 0;
                    width: 334px;
                    height: 40px;
                    border: 1.5px solid #2699fb;
                    border-radius: 4px;
                    font-family: 'Montserrat-Regular';
                    font-size: 12px;
                    &:focus {
                        outline: none;
                        border: 2px solid #2699fb;
                    }
                    &::placeholder {
                        color: #2699fb;
                    }
                }
                > span {
                    color: #2699fb;
                    > i {
                        position: absolute;
                        margin: 22px 0 0 -25px;
                    }
                }
            }
        }
    }
    .community__slider {
        display: flex;
        height: 60%;
        @include mobile {
            width: 100%;
        }
        .swiper {
            width: 100%;
            height: 100%;
            .swiper-wrapper {
                display: flex;
                align-items: center;
                .community__slider__items {
                    &:hover {
                        transform: scale(1.05);
                    }
                    padding: 20px;
                    box-sizing: border-box;
                    text-align: center;
                    width: 331px !important;
                    height: 383px;
                    border: 0px solid transparent;
                    border-radius: 10px;
                    background: rgb(38, 153, 251);
                    background: linear-gradient(
                        180deg,
                        rgba(38, 153, 251, 1) 0%,
                        rgba(4, 3, 48, 1) 100%
                    );
                    transition: 0.3s;
                    .community__slider__item {
                        .community__slider__social {
                            position: absolute;
                            left: 20px;
                            width: 32px;
                            height: 32px;
                            > a {
                                text-decoration: none;
                                > i {
                                    border: 0px solid transparent;
                                    border-radius: 1px;
                                    font-size: 32px;
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                    cursor: pointer;
                                    color: #2699fb;
                                }
                            }
                            &:hover {
                                //box-shadow: 0px 0px 4px 4px rgba(0, 0, 0, 0.25);
                                //outline: 4px solid rgba(255, 255, 255, 0.36);
                                color: #2699fb;
                                transition: 0.2s;
                            }
                        }
                        .community__slider__button {
                            position: absolute;
                            right: 20px;
                            border: 0px solid transparent;
                            background-color: rgb(255, 255, 255);
                            border-radius: 50%;
                            width: 25px;
                            height: 25px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            cursor: pointer;
                            &:hover {
                                box-shadow: 0px 0px 4px 4px rgba(0, 0, 0, 0.25);
                                outline: 4px solid rgba(255, 255, 255, 0.36);
                                color: #2699fb;
                                transition: 0.2s;
                                @include mobile {
                                    outline: none;
                                    outline: 4px solid rgba(255, 255, 255, 0.36);
                                    box-sizing: border-box;
                                }
                            }
                        }
                        .community__slider__content {
                            position: absolute;
                            bottom: 10px;
                            text-align: left;
                            > p {
                                color: white;
                            }
                        }
                    }
                }
            }
            .swiper-button-prev {
                top: 97%;
                left: 49.5%;
                @include mobile {
                    top: 96%;
                    left: 38%;
                }
            }
            .swiper-button-next {
                top: 97%;
                right: 49.5%;
                @include mobile {
                    top: 96%;
                    right: 38%;
                }
            }
        }
    }
}